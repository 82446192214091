import { Configuration, RedirectRequest, ProtocolMode } from '@azure/msal-browser';

const environment: Record<string, string> = {
  'https://localhost:5001': '973ea780-b032-48de-9822-707536a7cb9f',
  'https://dev.otcweb.econfigure.abb.com': '973ea780-b032-48de-9822-707536a7cb9f',
  'https://test.otcweb.econfigure.abb.com': '07b82f37-e1d7-4590-94d6-5d997bff69c0',
  'https://otcweb.econfigure.abb.com': 'bf221e34-7a8e-415f-a560-dc08ab7c0da4',
};

const environmentAud: Record<string, string> = {
  'https://docweb.econfigure.abb.com': '1deb6ec3-20e5-44b0-914a-1654854cb8b6',
  'https://localhost:5001': '08d2578c-6ab6-4da1-a1c8-3f9e12e6a582',
  'https://dev.docweb.econfigure.abb.com': '08d2578c-6ab6-4da1-a1c8-3f9e12e6a582',
  'https://test.docweb.econfigure.abb.com': '5e6c20ad-591e-4eed-81c9-3d393117435c',
};

const environmentAudPH: Record<string, string> = {
  dev: '08d2578c-6ab6-4da1-a1c8-3f9e12e6a582',
  test: '5e6c20ad-591e-4eed-81c9-3d393117435c',
  stage: '7e07715c-f67f-4ffb-ad11-ba27e700adde',
  prod: '1deb6ec3-20e5-44b0-914a-1654854cb8b6',
};

const getAudienceId = (): string => {
  const toolUrl = new URLSearchParams(window.location.search).get('toolUrl');
  if (toolUrl) {
    const phAudId = Object.keys(environmentAudPH).find((phEnv) => toolUrl.indexOf(phEnv) !== -1);
    if (phAudId) return environmentAudPH[phAudId];
  }
  return environmentAud[window.location.origin];
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: environment[window.location.origin] || 'bb8294eb-38ff-4d53-8023-484dc3b6e83b',
    authority: 'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd/',
    knownAuthorities: ['login.microsoftonline.com'],
    protocolMode: ProtocolMode.AAD,
    redirectUri: window.location.origin,
    // postLogoutRedirectUri: "/"
  },
  cache: {
    secureCookies: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [`https://ABB.onmicrosoft.com/${getAudienceId()}/user_impersonation`],
};
