/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import { jsPDF as JsPDF } from 'jspdf';
import $ from 'jquery';
import { TFunction } from 'i18next';
import { Dispatch } from 'redux';
import { uuid } from '../../utils';
import { PUSH_NOTIFICATION } from '../../store/app/types.d';
import { ApplicationState } from '../../store';
import { coolingSystemStrings, targetOfCalculationStrings, disposition, sidesCoverage } from '../pages/layout/constants';
import AbbVoiceBold from './AbbVoice-bold';
import AbbVoiceRg from './ABBvoice-RG';
import { saveProjectData } from '../../api/partnerHubApi';
import ABBLogo from '../../img/Abb-logo.svg';
import { PartnerHubProjectData } from '../../store/app/types.d';

const CELCIUS = '\xB0C';

const svgToImg = (svg: string): string => {
  // encode svg into base64 string to pass it as datasource
  const buff = Buffer.from(svg);
  return buff.toString('base64');
};

const createImgForPDF = (source: string, targetHeight: number, targetWidth: number): Promise<string> =>
  new Promise((imgResolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.height = targetHeight;
      canvas.width = targetWidth;
      const ctx = canvas.getContext('2d');
      if (ctx !== null) {
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);
      }

      const imgPng = canvas.toDataURL('image/png');
      imgResolve(imgPng);
    };
    img.src = source;
  });

export const generatePDF =
  (t: TFunction, sendToPartnerHub: boolean) =>
  async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
    const pdf = new JsPDF();
    // since jsPDF have 12 hardcoded fonts, it's needed to add them to it
    // fonts are just converted ttf files from
    // https://rawgit.com/MrRio/jsPDF/master/fontconverter/fontconverter.html
    // and stripped from all jsPDF stuff from there, leaving only base64 converted string of data

    // https://github.com/MrRio/jsPDF

    // TODO - add Russian, Arabic, Chinese fonts

    pdf.addFileToVFS('AbbVoice.ttf', AbbVoiceRg);
    pdf.addFileToVFS('AbbVoiceBold.ttf', AbbVoiceBold);
    // this would not work without providing 'StandardEncoding' arg no matter what docs will tell you...
    pdf.addFont('AbbVoice.ttf', 'AbbVoice', 'normal', 'StandardEncoding');
    pdf.addFont('AbbVoiceBold.ttf', 'AbbVoice', 'bold', 'StandardEncoding');
    pdf.setFont('AbbVoice', 'normal');

    const { inputsData, calculationResponseData, app } = getState();
    // controls line for pdf printing, max is 299 (on current settings (defaults))
    // get incremented after each addRowToPDF(), addLabelToPDF() etc
    // after pdf.addPage() this resets to 16 manually by now;
    let y = 16;
    // Chart of message which tells it's not avaible for selected method
    const addChartToPDF = async () => {
      if (inputsData.CoolingSystem === 0) {
        await createImgForPDF(`data:image/svg+xml;base64,${svgToImg(calculationResponseData.chart)}`, 750, 750)
          .then((chartImage) => {
            pdf.addImage(chartImage, 50, 15, 100, 100);
            y += 104;
          })
          .catch(() => console.log('addChartToImageError'));
      } else {
        pdf.text(`${t('OTC_LABEL_NOTEMPERATURECURVE')}`, 100, y, { align: 'center' });
        y += 8;
      }
    };
    // ABB-OTC + Abb logo
    // const logo = createImgForPDF(ABBLogo, imgCallback, 500, 500);
    const addPageHeader = (logo: string) => {
      pdf.addImage(logo, 195, 5, 10, 10);
      pdf.setFontSize(12);
      pdf.text('ABB-OTC', 90, 8);
    };

    // adds row: "label:        value" to pdf;
    const addRowToPDF = (name: string, value: string, unit = '') => {
      pdf.setFontSize(12);
      pdf.text(t(name), 20, y);
      if (unit) {
        pdf.text(`${t(value)} ${unit}`, 110, y);
      } else {
        pdf.text(t(value), 110, y);
      }
      y += 8;
    };

    // adds a Label with bold text with a short line slightly above it
    const addLabelRow = (label: string) => {
      pdf.rect(15, y, 10, 1, 'F');
      pdf.setFontSize(20);
      pdf.setFont('AbbVoice', 'bold');
      pdf.text(t(label), 15, y + 10);
      pdf.setFont('AbbVoice', 'bold');
      y += 20;
    };

    // gets Data from sides Coverage constant
    const addSidesInformation = (label: string, BoardInstallationType: number, displayType: 'coverage' | 'bFactor') => {
      addLabelRow(label);
      addRowToPDF('Top', sidesCoverage[BoardInstallationType].Top[displayType] as unknown as string);
      addRowToPDF('Front', sidesCoverage[BoardInstallationType].Front[displayType] as unknown as string);
      addRowToPDF('Back', sidesCoverage[BoardInstallationType].Back[displayType] as unknown as string);
      addRowToPDF('Side One', sidesCoverage[BoardInstallationType].SideOne[displayType] as unknown as string);
      addRowToPDF('Side Two', sidesCoverage[BoardInstallationType].SideTwo[displayType] as unknown as string);
    };

    const addAreaSidesInformation = (label: string, BoardInstallationType: number, displayType?: 'overral' | 'effective') => {
      addLabelRow(label);
      const { Width, Deep, Height } = inputsData.BoardDimensions;
      addRowToPDF(
        'Top',
        `${(Width * Deep * (displayType === 'effective' ? sidesCoverage[BoardInstallationType].Top.bFactor : 1)).toFixed(2)} m2`
      );
      addRowToPDF(
        'Front',
        `${(Width * Height * (displayType === 'effective' ? sidesCoverage[BoardInstallationType].Front.bFactor : 1)).toFixed(
          2
        )} m2`
      );
      addRowToPDF(
        'Back',
        `${(Width * Height * (displayType === 'effective' ? sidesCoverage[BoardInstallationType].Back.bFactor : 1)).toFixed(
          2
        )} m2`
      );
      addRowToPDF(
        'Side One',
        `${(Height * Deep * (displayType === 'effective' ? sidesCoverage[BoardInstallationType].SideOne.bFactor : 1)).toFixed(
          2
        )} m2`
      );
      addRowToPDF(
        'Side Two',
        `${(Height * Deep * (displayType === 'effective' ? sidesCoverage[BoardInstallationType].SideTwo.bFactor : 1)).toFixed(
          2
        )} m2`
      );
    };

    const addDispositionImage = () => {
      const img = new Image();
      img.src = `/assets/images/disposition${inputsData.BoardInstallationType}.jpg`;
      pdf.addImage(img, 20, y, 170, 100);
      y += 102;
    };
    // personal data part

    addLabelRow('OTC_LABEL_PERSONALDATA');
    addRowToPDF('OTC_PDF_USERNAME', app.partnerHub.userName);
    addRowToPDF('OTC_PDF_CUSTOMERNAME', app.partnerHub.customerName);
    addRowToPDF('OTC_PDF_PROJECTNAME', app.partnerHub.projectName);

    // cooling system part
    addLabelRow('OTC_LABEL_COOLING_SYSTEM');
    addRowToPDF('OTC_LABEL_COOLING_SYSTEM', coolingSystemStrings[inputsData.CoolingSystem]);
    addRowToPDF(
      'OTC_LABEL_TARGETOFCALCULATION',
      inputsData.CoolingSystem === 2 && inputsData.TargetOfCalculation === 2
        ? targetOfCalculationStrings[3]
        : targetOfCalculationStrings[inputsData.TargetOfCalculation]
    );
    if (inputsData.CoolingSystem === 0) {
      addRowToPDF('OTC_LABEL_VENTILATIONGRIDAREA', `${inputsData.BoardVentilationHoles} cm`);
    } else if (inputsData.CoolingSystem === 1) {
      if (inputsData.TargetOfCalculation !== 2) {
        addRowToPDF('OTC_LABEL_FANCAPACITY', `${inputsData.FanCapacity}`);
        addRowToPDF('OTC_PDF_COEFFICIENTOFTHERMALEXCHANGE', `${inputsData.CoefficientOfThermalExchange}`);
      }
    } else if (inputsData.CoolingSystem === 2 && inputsData.TargetOfCalculation !== 2) {
      addRowToPDF('OTC_LABEL_CONDITIONINGPOWER', `${inputsData.ConditioningPower.toFixed(2)}`);
    }

    // disposition part
    addLabelRow('OTC_PDF_DISPOSITIONANDDIMENSION');
    addRowToPDF('OTC_PDF_TYPEOFPLANT', `${inputsData.BoardInstallationType}`);
    addRowToPDF('OTC_INPUT_HEIGHT', `${inputsData.BoardDimensions.Height * 1000} mm`);
    addRowToPDF('OTC_INPUT_WIDTH', `${inputsData.BoardDimensions.Width * 1000} mm`);
    addRowToPDF('OTC_INPUT_DEPTH', `${inputsData.BoardDimensions.Deep * 1000} mm`);
    addRowToPDF('OTC_SELECT_HORIZONTALFRAMES', `${inputsData.NumberOfInternalHorizontalFrames}`);
    addRowToPDF('OTC_LABEL_DISPOSITION', `${disposition.display[inputsData.BoardInstallationType]}`);

    addDispositionImage();

    // page 2 start
    y = 16;
    pdf.addPage();

    // sides coverage
    addSidesInformation('OTC_PDF_SIDESCOVERAGE', inputsData.BoardInstallationType, 'coverage');
    // b Factor
    addSidesInformation('OTC_PDF_BFACTOR', inputsData.BoardInstallationType, 'bFactor');
    // sides area
    addAreaSidesInformation('OTC_PDF_SIDESAREA', inputsData.BoardInstallationType);
    // effective sides area
    addAreaSidesInformation('OTC_PDF_SIDESEFFECTIVEAREA', inputsData.BoardInstallationType, 'effective');
    // effective cooling area
    addRowToPDF('OTC_PDF_TOTALEFFECTIVECOOLINGAREA', `${calculationResponseData.effectiveCoolingAreaOfBoard.toFixed(2)} m2`);

    // page 3 start
    pdf.addPage();
    y = 16;

    // chart
    await addChartToPDF();

    // Air conditioner
    if (inputsData.CoolingSystem === 2) {
      // conditioning
      addLabelRow('OTC_PDF_AIRCONDITIONER');
      addRowToPDF('OTC_PDF_THERMALEXCOEFFICIENT', '5.50');
      addRowToPDF('OTC_PDF_COOLEFFICIENCY', `${calculationResponseData.correctionFactor.toFixed(2)}`);
      addRowToPDF('OTC_LABEL_CONDITIONINGPOWER', `${inputsData.ConditioningPower.toFixed(2)} W`);
    }

    // power losses
    addLabelRow('OTC_LABEL_POWERLOSSES');
    addRowToPDF('OTC_INPUT_DEVICERATEDPOWERLOSSES', `${inputsData.EffectivePowerLossOfInstalledDevices} W`);
    addRowToPDF('OTC_INPUT_DEMANDFACTOR', `${inputsData.DemandFactor}`);
    addRowToPDF(
      'OTC_INPUT_DEVICEPOWERLOSSES',
      `${(calculationResponseData.powerLoss - inputsData.PowerLossOfConductors - inputsData.ExtraPowerLoss).toFixed(2)} W`
    );
    addRowToPDF('OTC_INPUT_CONDUCTORPOWERLOSSES', `${inputsData.PowerLossOfConductors} W`);
    addRowToPDF('OTC_INPUT_EXTRAPOWERLOSS', `${inputsData.ExtraPowerLoss} W`);
    if (inputsData.CoolingSystem === 2) {
      addRowToPDF(
        'OTC_INPUT_POWERSUBSTRACTEDBYCONDITIONING',
        `${calculationResponseData.powerSubtractedByConditioning.toFixed(2)} W`
      );
      addRowToPDF('OTC_INPUT_POWERFROMAMBIENT', `${calculationResponseData.powerFromAmbient.toFixed(2)} W`);
    } else if (inputsData.CoolingSystem === 1) {
      addRowToPDF('OTC_INPUT_POWERSUBSTRACTEDBYFAN', `${calculationResponseData.powerToDissipateByFan.toFixed(2)} W`);
    }
    addRowToPDF('OTC_DISPLAY_TOTALPOWERLOSS', `${calculationResponseData.totalPowerLoss.toFixed(2)} W`);

    // target of calculation dependend rows
    // temperature profile
    addLabelRow('OTC_RADIO_TEMPERATUREPROFILE');
    if (inputsData.CoolingSystem === 0) {
      addRowToPDF('OTC_PDF_DELTAT1', `${calculationResponseData.overheatAtTopHeightOfBoard.toFixed(2)} K`);
      addRowToPDF('OTC_PDF_DELTAT05', `${calculationResponseData.overheatAtMiddleOfBoard.toFixed(2)} K`);
    }
    addRowToPDF('OTC_INPUT_AMBIENTTEMPERATURE', `${inputsData.ExternalTemperature}`, CELCIUS);
    if (
      (inputsData.TargetOfCalculation === 1 && inputsData.CoolingSystem !== 1) ||
      (inputsData.TargetOfCalculation === 2 && inputsData.CoolingSystem !== 1) ||
      (inputsData.CoolingSystem === 2 && inputsData.TargetOfCalculation === 0)
    ) {
      if (inputsData.CoolingSystem === 0 && inputsData.TargetOfCalculation === 1) {
        addRowToPDF('OTC_TEMPATMAXHEIGHT', `${inputsData.MaxAverageInternalTemperature}`, CELCIUS);
      } else {
        addRowToPDF('OTC_INPUT_MAXEVERAGEINTERNALTEMPERATURE', `${inputsData.MaxAverageInternalTemperature}`, CELCIUS);
      }
    }
    if (inputsData.CoolingSystem === 1) {
      addRowToPDF('OTC_TEMPATMAXHEIGHT', `${inputsData.MaxAverageInternalTemperature}`, CELCIUS);
    }

    // losable power
    if (inputsData.TargetOfCalculation === 1) {
      addLabelRow('OTC_RADIO_LOSABLEPOWER');
      addRowToPDF('OTC_DISPLAY_POWERLOSSESATTHEEND', `${calculationResponseData.maxPowerLossesAdmittedInTheEnd.toFixed(2)} W`);
      addRowToPDF('OTC_DISPLAY_LOSABLEPOWER', `${calculationResponseData.losablePower.toFixed(2)} W`);
    }

    // Fan Capacity / Air Conditioning
    if (inputsData.TargetOfCalculation === 2) {
      // Fan
      if (inputsData.CoolingSystem === 1) {
        addLabelRow('OTC_LABEL_FANCAPACITY');
        addRowToPDF('OTC_LABEL_FANCAPACITY', `${calculationResponseData.fanCapacity.toFixed(2)} m3/h`);
      }
    }

    // headers, listing etc
    const totalPages = pdf.getNumberOfPages();

    const logo = await createImgForPDF(ABBLogo, 500, 500);
    Array.from(Array(totalPages).keys()).forEach((pageNumber) => {
      pdf.setPage(pageNumber + 1);
      addPageHeader(logo);
      pdf.text(`${t('OTC_PDF_PAGE')} ${pageNumber + 1} / ${totalPages}`, 185, 292);
    });

    if (sendToPartnerHub === false) {
      // clear printIframes if somehow user will add more than 1 printIFrame
      let i;
      const printFrames = $('[name="printiFrame"]');
      for (i = 0; i < printFrames.length; i++) {
        printFrames[i].remove();
      }

      // setPDF to autoprint when open
      pdf.autoPrint();
      const blob = pdf.output('blob');

      // open print prompt once pdf is loaded

      const url = window.URL.createObjectURL(blob);
      const iframeElement = document.createElement('iframe');
      iframeElement.style.display = 'none';
      iframeElement.src = url;
      iframeElement.name = 'printiFrame';
      document.body.appendChild(iframeElement);
    } else {
      const blobPDF = pdf.output('blob');
      const reader = new FileReader();
      reader.readAsDataURL(blobPDF);
      reader.onloadend = () => {
        const base64data = reader.result;
        if (typeof base64data === 'string') {
          const partnerHubProjectData: PartnerHubProjectData = {
            ProjectId: app.partnerHub.projectId,
            Id: app.partnerHub.otcId,
            User: app.partnerHub.user,
            hookUrlUploadDocuments: app.partnerHub.hookUrlUploadDocuments,
            Documents: [
              {
                DocumentName: `${app.partnerHub.projectName} - ${app.partnerHub.otcName}`,
                DocumentType: 'pdf',
                Content: base64data.split('data:application/pdf;base64,')[1] as unknown as string,
              },
            ],
          } as PartnerHubProjectData;
          saveProjectData(partnerHubProjectData)
            .then(() => {
              dispatch({
                type: PUSH_NOTIFICATION,
                notification: {
                  id: uuid(),
                  type: 'banner',
                  discreet: true,
                  severity: 'success',
                  timeout: 2000,
                  text: t('OTC_DOCUMENTATION_WAS_SENT'),
                },
              });
            })
            .catch(console.warn);
        } else {
          console.log('sentToPHData, reader returned not base 64 string');
        }
      };
    }
  };
