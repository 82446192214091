import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { encode } from 'js-base64';
import { saveProjectData } from '../api/partnerHubApi';
import { ApplicationState } from '../store';

let timeout: NodeJS.Timeout | number = 0;

const AutoSave: FunctionComponent = (): React.ReactElement => {
  const allState = useSelector((state: ApplicationState) => state);
  if (timeout) {
    clearTimeout(timeout as NodeJS.Timeout);
    timeout = 0;
  }
  timeout = setTimeout(() => {
    const calculationProperties: ApplicationState = { ...allState };
    const projectData = encode(JSON.stringify(calculationProperties));
    const partnerHubProjectData = {
      ProjectId: allState.app.partnerHub.projectId,
      Id: allState.app.partnerHub.otcId,
      User: allState.app.partnerHub.user,
      hookUrlUploadDocuments: allState.app.partnerHub.hookUrlUploadDocuments,
      Documents: [
        {
          DocumentName: `${allState.app.partnerHub.otcName}`,
          DocumentType: 'otcweb',
          Content: projectData,
          IsProjectSettings: true,
        },
      ],
    };
    if (allState.app.partnerHub.hookUrlUploadDocuments && Object.keys(allState.inputErrors).length === 0) {
      saveProjectData(partnerHubProjectData)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }, 1500);

  return <></>;
};

export default AutoSave;
