export const CHANGE_MODE = 'CHANGE_MODE';
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const SET_PARTNER_HUB_DATA = 'SET_PARTNER_HUB_DATA';

export interface AppState {
  name: string;
  projectLoaded: boolean;
  projectId: number;
  isSetupMode: boolean;
  notifications: Array<NotificationData>;
  partnerHub: PartnerHubData;
}

export interface PartnerHubData {
  toolUrl: string;
  hookUrlUploadDocuments: string;
  projectId: string;
  projectData: string | null;
  otcId: string;
  user: string;
  userName: string;
  projectName: string;
  customerName: string;
  otcName: string;
}

export interface PartnerHubProjectData {
  ProjectId: string;
  Id: string;
  User: string;
  hookUrlUploadDocuments: string;
  Documents: Array<Documents>;
}

interface Documents {
  DocumentName: string;
  DocumentType: string;
  Content: string;
  IsProjectSettings: boolean;
}

export interface PartnerHubRequest {
  id: string;
  user: string;
  toolUrl: string;
}

export interface NotificationData {
  /** HTML standard attribute. Additional class name(s) applied to component root element.  */
  className?: string;
  /** HTML standard attribute. Custom styles applied to component root element.  */
  style?: React.CSSProperties;
  /** Custom id can be given to each notification. This id will be given as an
   *  attribute to the closing handler.
   */
  id: string;
  /** Notification has two variants: 'banner' has only close icon,
   *  whereas 'confirmation' can include any custom buttons.
   */
  type: 'confirmation' | 'banner';
  /** White/dark instead of solid color background. */
  discreet: boolean;
  /** Controls the color and icon of the notification. */
  severity: 'success' | 'info' | 'warn' | 'alarm';
  /** Notification disappears automatically after the timeout (in milliseconds) */
  timeout: number;
  /** Message shown in the notification. */
  text: string;
  /** Array of custom buttons, with text and value handler.
   * Specify only one button as 'primary'.
   */
  buttons?: [
    {
      text: string;
      primary?: boolean;
      handler: (notificationId: string) => void;
    },
  ];
  /** Set this on to collapse the notification upwards, and remove the
   * notification manually after it has fully collapsed.
   * Note: this is optional, and if not given, the Notification component will
   * handle the closing animation by itself.
   */
  isClosing?: boolean;
  /** Called when the user click the notification in any other part than one of its buttons. */
  onClick?: (id: string, event: React.MouseEvent<HTMLElement>) => void;
  /** Called when the notification has just closed. The notification will not
   * remove by itself (it is just marked as hidden), but the application must
   * then remove it. However, instead of handling this event, consider providing
   * actionHandler in NotificationContainer, that will be called whenever any
   * Notification is closed. That way, you don't have to bind onClose-handler
   * separately for each Notification.
   */
  onClose?: (id: string, event: React.MouseEvent<HTMLElement>) => void;
}

export interface ChangeModeState {
  type: 'CHANGE_MODE';
  params: {
    isSetupMode: boolean;
  };
}

export interface PushNotification {
  type: 'PUSH_NOTIFICATION';
  notification: NotificationData;
}

export interface SetPartnerHubData {
  type: 'SET_PARTNER_HUB_DATA';
  partnerHubData: PartnerHubData;
}
